.App {
  
}
body {
  font-family: 'Lato', sans-serif;
  background-color: #efefef!important;
  padding-top: 75px;

  @media screen and (max-width: 576px) {
    padding-top:0;
    padding-bottom: 80px;
  }
}
nav.navbar{
  &.fixed-bottom {
    @media screen and (min-width: 577px) {
      bottom: auto!important;
      top: 0!important;
    }
  }
  

  .navbar-brand{
    font-family: 'Anton', sans-serif;
    font-size:30px;
    text-transform:uppercase;
    margin-right: 0!important;
  
    .logo-bg {
      width: 50px;
      height: 50px;
    }
  }

  .nav {
    font-size: 12px;

    @media screen and (min-width: 577px) {
      font-size: 16px;
    }

    .nav-link{
      color: #fff;
    }
  }
}

.search-bar {
  margin-top: -2px;
}


.card {
  border-radius: 20px;
  border-color: #fff;

  img.thumbnail {
    width: 50%;
    margin-top: 40px;

    @media screen and (max-width: 576px) {
      width: 100%;
      
    }
  }
}

.text-primary {
  color: #BD03BD!important;
}
.text-secondary {
  color: #1F003A!important;
}
.active > .page-link, .page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #BD03BD!important;
  border-color: #BD03BD!important;
}
.page-link{
color: #BD03BD;
  &:hover {
    color: #BD03BD;
  }
}
li.break{
  background-color: #fff;
  padding-left: 20px;
  padding-right: 15px;
  border-top: #dee2e6 1px solid;
  border-bottom: #dee2e6 1px solid;
  letter-spacing:5px;
  text-align: center;

  @media screen and (max-width: 576px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.bg-primary {
  background-color: #BD03BD!important; //#457b9d!important;
}
.bg-secondary { 
  background-color: #1F003A!important; 
}
.btn-primary {
  background-color: #BD03BD!important;
  border-color: #BD03BD;
  color: #fff;
  &:focus {
    background-color: #BD03BD!important;
    border-color: #BD03BD;
  }
  svg {
    fill: #fff;
  }
}
.btn-outline-primary {
  //background-color: #BD03BD!important;
  border-color: #BD03BD;
  color: #BD03BD;
  &:hover {
    background-color: #BD03BD;
    border-color: #BD03BD;
    svg {
      fill: #fff;
    }
  }
  &:focus {
    background-color: #fff;
    border-color: #BD03BD;
    svg {
      fill: #BD03BD;
    }
  }
  svg{
    fill: #BD03BD;
  }
}
.btn-secondary {
  background-color: #AEC3FF!important;
  border-color: #AEC3FF!important;
  color: #213F7A;
  font-weight: 600;
}
.bg-light {
  background-color: #EBF3FA!important;
}
.bg-dark {
  background-color: #2E3552!important;
}
.text-dark {
  color:#1d3557!important;
}
.bg-danger {
  background-color: #e63946!important;
}
.btn-danger {
  background-color: #e63946!important;
  border-color: #e63946!important;
}
.header-image{
  background-image: url(../public/images/header2-8393-4296-8c54-80d1ba39afa6.jpg);
  min-height: 900px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #fff;

  @media screen and (max-width: 576px) {
    background-position: right 30% bottom 45%;
  }
  

  h1 {
    font-size: 55px;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    //width: 40%;
    @media screen and (max-width: 576px) {
      font-size: 30px;
    }
    
  }
  .logo-bg{
    width: 300px;
    height: 300px;
    img{
      max-width: 200px;
    }
  }
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.btn.icon-open {
  left: auto;
  right: 0;
  bottom:auto;
  top:0;
}
.btn.btn-icon{
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.nav {
  &.filter-tabs {
    .nav-item{

      .nav-link{
        text-transform: uppercase;
        font-weight: 600;
        color: #213F7A;
        border-radius:0;
        font-size: 14px;
      }
    }
  }
}
.btn-sm{
  font-size: 11px;
}

.favorite-card {
  @media screen and (max-width: 576px) {
    h3 {
      font-size: 16px;
    }
    .info {
      font-size: 12px;
    }
    .action-buttons .btn{
      font-size: 12px;
    }
    
  }
}